<template>
  <div class="parameter-subsidiary">
    <!-- 查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '120px',
      }"
      class="formStyle"
      @handleFilter="handleFilter('default')"
      @clearParams="clearParams"
    />
    <!-- 放款金额&文件导出 -->
    <div class="download">
      <span style="float: left">本月收件{{ monthCount || 0 }}笔，本周收件{{ weekCount || 0 }}笔</span>
      <base-button
        label="导出"
        @click="fileDownload()"
        icon="iconfont icondaochu"
      />
    </div>
    <!-- 列表 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: false,
        spanMethod: objectSpanMethod,
        border: true
      }"
      ref="tableData"
      :webPage="true"
      :queryParas.sync="queryParas"
      @pageIndexChange='pageIndexChange'
      @pageSizeChange='pageSizeChange'
    >
      <template slot="index" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          @click="edit(scope.row)"
          content="编辑"
          icon="iconfont iconbianji3"
        />
      </template>
    </base-table>
    <!-- 操作弹窗 -->
    <EditDialog :visible.sync="visible" :tableItem="tableItem" @update="handleFilter"></EditDialog>
  </div>
</template>
<script>
import baseForm from '@/components/common/base-form//base-form.vue'
import { dataForm, dataTable } from './utils/config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { riskApi } from '@/api/parameterApi'
// import { formatDate } from '@/utils/auth/common'
import { getDictLists } from '@/filters/fromDict'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import EditDialog from './components/edit-dialog.vue'
export default {
  name: 'parameterSubsidiary',
  components: { baseForm, BaseTable, BaseButton, IconButton, EditDialog },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      monthCount: '',
      weekCount: '',
      tableData: [],
      spanAll: [],
      pos: 0,
      tableItem: {},
      visible: false
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return riskApi
    },
    // 融资状态
    progressList () {
      return getDictLists('PROGRESS_STATUS')
    }

  },
  mounted () {
    this.setTime()
    Promise.all([
      this.handleFilter(),
      this.riskCount()
    ])
  },
  methods: {
    // 默认设置当前时间到前3个月时间段
    setTime () {
      const today = new Date()
      const oldDate = new Date(new Date().setMonth(new Date().getMonth() - 3))
      const endTime = today.getFullYear() + '-' + ((today.getMonth() + 1) >= 10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1)) + '-' + (today.getDate() >= 10 ? today.getDate() : '0' + today.getDate())
      const startTime = oldDate.getFullYear() + '-' + ((oldDate.getMonth() + 1) >= 10 ? (oldDate.getMonth() + 1) : '0' + (oldDate.getMonth() + 1)) + '-' + (today.getDate() >= 10 ? today.getDate() : '0' + today.getDate())
      this.$set(this.queryParas, 'endTime', endTime)
      this.$set(this.queryParas, 'startTime', startTime)
      this.$set(this.queryParas, 'Lendingdata', [startTime, endTime])
    },
    handleFilter (type) {
      this.spanAll = []
      this.pos = 0
      this.tableData = []
      if (type === 'default') {
        this.queryParas.pageIndex = 1
      }
      this.api.getTableList(this.queryParas).then(res => {
        if (res.data) {
          this.getSpanId(res.data.records)
          this.tableData = res.data.records
          this.$nextTick(() => {
            this.$refs.tableData.total = res.data.total
          })
        }
      })
      this.riskCount()
    },
    pageIndexChange (index) {
      this.queryParas.pageIndex = index
      this.handleFilter()
    },
    pageSizeChange (size) {
      this.queryParas.pageSize = size
      this.handleFilter()
    },
    getSpanId (data) {
      data.forEach((ele, index) => {
        if (index === 0) {
          this.spanAll.push(1)
        } else {
          if (ele.riskManagerId === data[index - 1].riskManagerId) {
            this.spanAll[this.pos] += 1
            this.spanAll.push(0)
          } else {
            this.spanAll.push(1)
            this.pos = index
          }
        }
      })
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1 || columnIndex === 2) {
        const row = this.spanAll[rowIndex]
        const col = row > 0 ? 1 : 0
        return {
          rowspan: row,
          colspan: col
        }
      }
    },
    // 融资台账总的还款金额
    riskCount () {
      this.api.riskCount(this.queryParas).then((res) => {
        if (res.success) {
          this.monthCount = res.data.monthCount
          this.weekCount = res.data.weekCount
        }
      })
    },
    // 重置
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
    },
    // 文件导出
    fileDownload () {
      this.api
        .exportList(this.queryParas)
        .then((res) => {
          if (res.data.type === 'application/json') {
            this.error('风控分配汇总查询结果为空')
            return
          }
          const url = URL.createObjectURL(res.data)
          const a = document.createElement('a')
          a.href = url
          a.download = '风控分配统计表.xlsx'
          a.click()
          URL.revokeObjectURL(url)
        })
        .catch((error) => {
          console.log('表格导出错误', error)
        })
    },
    // 编辑信息
    edit (row) {
      this.tableItem = JSON.parse(JSON.stringify(row))
      this.visible = true
    }
  }
}
</script>
<style lang="scss" src='./index.scss' scoped />

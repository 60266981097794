//编辑信息
<template>
  <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    title="编辑信息"
    class="addSginer"
    width="800px"
  >
    <base-form
      :componentList="editFrom"
      :showBtns="false"
      :formAttrs="{
        model: riskQuery,
      }"
      class="formStyle"
      ref="riskData"
    >
    </base-form>
    <template slot="footer">
      <base-button label="提交" @click="submitRisk"/>
      <base-button label="取消" @click="visibleState = false" />
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import { riskApi } from '@/api/parameterApi'
export default {
  props: {
    visible: Boolean,
    tableItem: Object
  },
  data () {
    return {
      riskQuery: {},
      personnelList: []
    }
  },
  components: { BaseDialog, BaseButton, BaseForm },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
        if (!val) {
          this.$nextTick(() => {
            this.$refs.riskData.clearValidate()
          })
        }
      }
    },
    editFrom () {
      return [
        {
          label: '暂缓状态',
          prop: 'stagingStatus',
          attrs: {
            placeholder: '请选择',
            options: [
              { name: '否', id: '0' },
              { name: '是', id: '1' }
            ],
            clearable: false
          },
          tag: BaseSelect,
          span: 8
        },
        {
          label: '备注',
          prop: 'remark',
          attrs: {
            placeholder: '请输入',
            type: 'textarea',
            rows: 3,
            maxLength: 2000
          },
          span: 24
        }
      ]
    }

  },
  watch: {
    visible (val) {
      if (val) {
        this.riskQuery = Object.assign({}, { businessId: this.tableItem.keyId, stagingStatus: this.tableItem.stagingStatus || '0', remark: this.tableItem.remark || '' })
      } else {
        this.riskQuery = {}
      }
    }
  },

  methods: {
    // 提交
    submitRisk () {
      this.$refs.riskData.validate(valid => {
        if (valid) {
          const params = {
            ...this.riskQuery
          }
          riskApi.editStatusInfo(params).then(res => {
            this.$emit('update')
            this.success('修改成功')
            this.visibleState = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.addSginer {
  margin-top: 200px;
  height: 500px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"parameter-subsidiary"},[_c('base-form',{staticClass:"formStyle",attrs:{"componentList":_vm.formConfig,"formAttrs":{
      model: _vm.queryParas,
      labelWidth: '120px',
    }},on:{"handleFilter":function($event){return _vm.handleFilter('default')},"clearParams":_vm.clearParams}}),_c('div',{staticClass:"download"},[_c('span',{staticStyle:{"float":"left"}},[_vm._v("本月收件"+_vm._s(_vm.monthCount || 0)+"笔，本周收件"+_vm._s(_vm.weekCount || 0)+"笔")]),_c('base-button',{attrs:{"label":"导出","icon":"iconfont icondaochu"},on:{"click":function($event){return _vm.fileDownload()}}})],1),_c('base-table',{ref:"tableData",staticClass:"main-page-table",attrs:{"columns":_vm.columns,"showPage":true,"tableAttrs":{
      data: _vm.tableData,
      stripe: false,
      spanMethod: _vm.objectSpanMethod,
      border: true
    },"webPage":true,"queryParas":_vm.queryParas},on:{"update:queryParas":function($event){_vm.queryParas=$event},"update:query-paras":function($event){_vm.queryParas=$event},"pageIndexChange":_vm.pageIndexChange,"pageSizeChange":_vm.pageSizeChange},scopedSlots:_vm._u([{key:"index",fn:function(scope){return [_vm._v(" "+_vm._s(scope.$index + 1)+" ")]}},{key:"action",fn:function(scope){return [_c('icon-button',{attrs:{"content":"编辑","icon":"iconfont iconbianji3"},on:{"click":function($event){return _vm.edit(scope.row)}}})]}}])}),_c('EditDialog',{attrs:{"visible":_vm.visible,"tableItem":_vm.tableItem},on:{"update:visible":function($event){_vm.visible=$event},"update":_vm.handleFilter}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
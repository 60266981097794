import BaseSelect from '@/components/common/base-select/base-select.vue'
import { formatDate } from '@/utils/auth/common'

// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '时间',
      prop: 'Lendingdata',
      isHidden: content.fold,
      attrs: {
        type: 'daterange',
        rangeSeparator: '至',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        'value-format': 'yyyy-MM-dd'
      },
      tag: 'el-date-picker',
      on: {
        change: (data) => {
          if (data && data.length > 0) {
            content.queryParas.endTime = data[1]
            content.queryParas.startTime = data[0]
          } else {
            content.queryParas.endTime = ''
            content.queryParas.startTime = ''
          }
        }
      }
    },
    {
      label: '融资状态',
      prop: 'financeStatus',
      attrs: {
        placeholder: '请选择',
        options: content.progressList,
        selectedField: ['dictId', 'dictName'],
        filterable: true
      },
      tag: BaseSelect,
      on: {
        change: (data) => {
          // content.changeQueryStatus(data)
        }
      }
    }
  ]
}
// 表格form
export const dataTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '60px'
    },
    {
      label: '风控经理',
      prop: 'riskManagerName'
    },
    {
      label: '当年个人累计业务量',
      prop: 'businessCount',
      width: '160px',
      'show-overflow-tooltip': true
    },
    {
      label: '收件时间',
      prop: 'pickupTime',
      'show-overflow-tooltip': true,
      formatter: row => {
        return formatDate(row.pickupTime, 'YY-MM-DD')
      }
    },
    {
      label: '业务经理',
      prop: 'businessManager',
      'show-overflow-tooltip': true
    },
    {
      label: '客服名称',
      prop: 'customerName',
      minWidth: '120px',
      'show-overflow-tooltip': true
    },
    {
      label: '产品类型',
      prop: 'financeProductType'
    },
    {
      label: '融资金额(万元)',
      prop: 'financeAmount',
      formatter: row => {
        return (row.financeAmount ? row.financeAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      },
      minWidth: '120px',
      'show-overflow-tooltip': true,
      align: 'right'
    },
    {
      label: '备注',
      prop: 'remark',
      align: 'left',
      minWidth: '120px',
      formatter: row => {
        return row.remark ? row.remark : '--'
      }
    },
    {
      label: '暂缓状态',
      prop: 'stagingStatus',
      formatter: row => {
        return row.stagingStatus === '1' ? '是' : (row.stagingStatus === '0' ? '否' : '--')
      }
    },
    {
      label: '融资状态',
      prop: 'financeStatus',
      'show-overflow-tooltip': true
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
